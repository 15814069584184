<template>
  <div>
    <a-modal
      :title="noticeTitle"
      :visible="visible"
      :confirm-loading="loading"
      :maskClosable="false"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <p>让这些驻场成为代理商“{{ record ? record.name : '-' }}”的驻场？</p>
    </a-modal>
  </div>
</template>

<script>
import { agentAddResident } from '@/api/agent'
export default {
  props: {
    residents: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      noticeTitle: '添加代理商驻场',
      record: {},
      visible: false,
      loading: false
    }
  },
  methods: {
    showModal(record) {
      this.record = record
      this.visible = true
    },
    handleOk() {
      this.loading = true
      const agentId = this.record.id
      const residentIds = this.residents
        .map(item => {
          console.log(item)
          const value = item.key.split(',')
          const id = value[1]
          return id
        })
        .join(',')
      const param = {
        agentId,
        residentIds
      }
      agentAddResident(param)
        .then(res => {
          console.log(res, 'res');
          if (res.success) {
            if (res.message) {
              this.notiError(this.noticeTitle, res.message)
            } else {
              this.notiSuccess(this.noticeTitle, '添加代理商的驻场成功')
            }
            this.visible = false
            this.$emit('reload')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleCancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped></style>
