<template>
  <a-modal
    title="代理商"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="handleOk">
        确定
      </a-button>
    </template>
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="代理商名称">
          <a-input
            placeholder="请输入代理商名称"
            v-decorator="['name', { rules: [{ required: true, message: '请输入代理商名称' }] }]"
            allow-clear
            :maxLength="15"
          ></a-input>
        </a-form-item>
        <a-form-item label="企业代码">
          <a-input allow-clear placeholder="请输入企业代码" v-decorator="['code', { rules: [{ required: true, message: '请输入企业代码' }] }]" :maxLength="limitLength" />
        </a-form-item>
        <a-form-item label="所在地区">
          <a-cascader
            allow-clear
            v-decorator="['area', { rules: [{ required: true, message: '请输入所在地区' }] }]"
            :fieldNames="cityField"
            placeholder="请选择所在地区"
            :options="options"
            @change="onChange"
          />
        </a-form-item>
        <a-form-item label="详细地址">
          <a-input allow-clear placeholder="请输入详细地址" v-decorator="['address']" :maxLength="limitLength" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { agentGet, agentAdd, agentEdit } from '@/api/agent'

// 表单字段
const fields = ['name', 'code', 'area', 'address']

export default {
  data() {
    return {
      noticeTitle: '代理商管理',
      visible: false,
      loading: false,
      id: null,
      model: null,
      form: this.$form.createForm(this),
      cityField: { label: 'name', value: 'name', children: 'children' }
    }
  },
  created() {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  computed: {
    options() {
      const city = this.defaultCityOptions
      if (this.$store.state.city.city) {
        return this.$store.state.city.city
      } else {
        return city
      }
    }
  },
  methods: {
    showModal(record = null) {
      this.loading = false
      this.model = null
      this.form.resetFields()
      if (record) {
        this.id = record.id
        this.fetchInfo()
      } else {
        this.visible = true
      }
    },
    handleOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.loading = true
          const area = values.area
          const param = { ...values, province: area[0], city: area[1], district: area[2], street: area[3] }
          if (this.model) {
            this.patchInfo(this.id, param)
          } else {
            this.newInfo(param)
          }
        } else {
          this.loading = false
        }
      })
    },
    handleCancel() {
      this.visible = false
    },
    fetchInfo() {
      this.visible = true
      this.loading = true
      agentGet(this.id)
        .then(res => {
          this.model = res
          this.model.area = [res.province, res.city, res.district, res.street]
        })
        .finally(() => {
          this.loading = false
        })
    },
    newInfo(param) {
      setTimeout(() => {
        if (this.visible) {
          agentAdd(param)
            .then(res => {
              if (res.id) {
                this.visible = false
                this.$emit('reload')
                this.$notification.success({ message: this.noticeTitle, description: '新建成功' })
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      }, this.asyncTime)
    },
    patchInfo(id, param) {
      setTimeout(() => {
        if (this.visible) {
          agentEdit(id, param)
            .then(res => {
              if (res.id) {
                this.visible = false
                this.$emit('reload')
                this.$notification.success({ message: this.noticeTitle, description: '修改成功' })
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      }, this.asyncTime)
    },
    onChange(value) {
      console.log(value, 'city')
    }
  }
}
</script>
