<template>
  <a-drawer
    title="代理商详情"
    placement="right"
    width="500px"
    class="myDescription"
    :closable="false"
    :visible="visible"
    :after-visible-change="afterVisibleChange"
    @close="handleCancel"
  >
    <a-card :bordered="false">
      <a-spin :spinning="loading">
        <a-descriptions title="基本信息">
          <a-descriptions-item label="代理商名称" span="3">{{ model.name | textFilter }}</a-descriptions-item>
          <a-descriptions-item label="所在地区" span="3">{{
            model.area ? model.area.join('-') : '--'
          }}</a-descriptions-item>
          <a-descriptions-item label="创建时间" span="3">{{
            model.createTime | textFilter
          }}</a-descriptions-item>
        </a-descriptions>
        <a-table :rowKey="record => record.id" :columns="columns" :data-source="resident"></a-table>
      </a-spin>
    </a-card>
  </a-drawer>
</template>

<script>
import { agentGet, agentQueryResident } from '@/api/agent'
export default {
  data() {
    return {
      noticeTitle: '代理商管理',
      visible: false,
      loading: false,
      id: null,
      model: {},
      resident: [],
      form: this.$form.createForm(this),
      columns: [
        {
          title: '驻场',
          dataIndex: 'residentName'
        },
        {
          title: '手机号',
          dataIndex: 'residentPhone'
        }
      ]
    }
  },
  created() {},
  methods: {
    showModal(record) {
      this.visible = true
      const id = record.id
      this.fetchAgent(id)
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.model = {}
    },
    afterVisibleChange(val) {
      console.log('visible', val)
    },
    fetchAgent(id) {
      this.loading = true
      agentGet(id).then(res => {
        if (res.id) {
          this.model = res
          this.model.area = [res.province, res.city, res.district, res.street]
          this.fetchAgentResident(id)
        }
      })
    },
    fetchAgentResident(id) {
      const param = {
        agentId: id,
        ...this.defaultPagination
      }
      agentQueryResident(param)
        .then(res => {
          if (res.success) {
            this.resident = res.data.content
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
