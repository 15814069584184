<template>
  <div>
    <a-drawer :title="title" placement="right" width="800" :visible="visible" @close="handleCancel">
      <div>
        <a-row :gutter="48" style="margin-bottom: 20px;">
          <a-col :md="16" :sm="24">
            <a-select
              allow-clear
              mode="multiple"
              label-in-value
              placeholder="选择驻场"
              :value="select"
              style="width: 100%"
              @change="handleChange"
              option-label-prop="label"
            >
              <a-select-option v-for="item in fetch" :key="item.value" :value="item.value" :label="item.name">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-button type="primary" @click="addRow">添加</a-button>
          </a-col>
        </a-row>
        <div class="table-operator">
          <a-button type="danger" icon="delete" @click="deleteRow">移除</a-button>
        </div>
        <a-table
          ref="drawerTable"
          size="default"
          bordered
          :rowKey="record => record.id"
          :columns="columns"
          :data-source="current"
          :pagination="pagination"
          :rowSelection="rowSelection"
          :loading="loading"
          @change="tableChange"
        >
          <span class="myCol" slot="name" slot-scope="text">
            {{ text }}
          </span>

          <span slot="id" slot-scope="text">
            <a-tag color="blue">{{ text }}</a-tag>
          </span>
        </a-table>
      </div>
    </a-drawer>
    <resident-add ref="addModal" :record="record" :residents="select" @reload="reload"></resident-add>
    <resident-delete ref="deleteModal" :record="record" :selectedRows="selectedRows" @reload="reload"></resident-delete>
  </div>
</template>

<script>
import residentAdd from './residentAdd'
import residentDelete from './residentDelete'
import { agentQueryResident } from '@/api/agent'
import { residentQuery } from '@/api/residents'
export default {
  name: 'PersonDrawer',
  components: {
    residentAdd,
    residentDelete
  },
  data() {
    return {
      id: null,
      title: '代理商',
      record: null,
      visible: false,
      select: [],
      fetch: [],
      current: [],
      pagination: {
        current: 1,
        results: 10
      },
      loading: false,
      columns: [
        {
          title: '驻场',
          dataIndex: 'residentName',
          scopedSlots: { customRender: 'residentName' }
        },
        {
          title: '手机号',
          dataIndex: 'residentPhone',
          scopedSlots: { customRender: 'residentPhone' }
        }
      ],
      // 删除
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  methods: {
    reload() {
      this.listFetch()
    },
    delReload() {
      this.selectedRows = []
      this.listFetch()
    },
    deleteRow() {
      const len = this.selectedRows.length
      if (len <= 0) {
        this.$message.warning('请选择要删除的项', 2)
      } else {
        this.$refs.deleteModal.showModal()
      }
    },
    showModal(record) {
      this.id = record.id
      this.record = record
      this.title = record.name
      this.visible = true
      this.listFetch()
    },
    handleCancel() {
      this.select = []
      this.visible = false
      this.selectedRowKeys = []
      this.pagination = {
        current: 1,
        results: 10
      }
    },
    handleChange(value) {
      this.select = value
    },
    listFetch() {
      this.loading = true
      this.select = []
      const param = {
        agentId: this.id,
        page: this.pagination.current - 1,
        size: this.pagination.results
      }
      agentQueryResident(param)
        .then(res => {
          this.current = res.data.content
          this.pagination = {
            current: res.data.number + 1,
            results: res.data.size,
            total: res.data.totalElements
          }
          this.loadResident()
        })
        .finally(() => {
          this.loading = false
        })
    },
    tableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.listFetch();
    },
    loadResident() {
      const param = { ...this.maxPagination }
      residentQuery(param).then(res => {
        const allResident = res.data.content.map(item => {
          return {
            name: item.name,
            text: `${item.name}(${item.phone})`,
            value: item.name + ',' + item.id,
            phone: item.phone
          }
        })
        const allId = allResident.map(item => {
          const value = item.value.split(',')
          return value[1]
        })
        const currentId = this.current.map(item => item.residentId)
        const arr = []
        allId.filter((item, index) => {
          if (!currentId.includes(item)) {
            arr.push(allResident[index])
          }
        })
        this.fetch = arr
      })
    },
    addRow() {
      const len = this.select.length
      if (len === 0) {
        this.$message.warning('请先选择驻场')
      } else {
        this.$refs.addModal.showModal(this.record)
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>

<style lang="less" scoped></style>
