<template>
  <a-modal
    title="刪除代理商"
    :visible="visible"
    :confirm-loading="loading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <p>确定要删除这些代理商吗？</p>
  </a-modal>
</template>

<script>
import { agentDelete } from '@/api/agent'

export default {
  props: {
    selectedRows: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      noticeTitle: '代理商管理',
      visible: false,
      loading: false
    }
  },
  methods: {
    showModal() {
      this.visible = true
    },
    handleOk() {
      this.loading = true
      setTimeout(() => {
        if (this.visible) {
          const agentIds = this.selectedRows
            .map(item => {
              return item.id
            })
            .join(',')
          const param = {
            agentIds
          }
          agentDelete(param)
            .then(res => {
              if (res.success) {
                this.visible = false
                this.$notification.success({
                  message: this.noticeTitle,
                  description: '代理商删除成功！'
                })
                this.$emit('reload')
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      }, this.asyncTime)
    },
    handleCancel() {
      this.visible = false
      this.loading = false
    }
  }
}
</script>
