<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="代理商名称">
                <a-input v-model="queryParam.name" allow-clear placeholder="请输入代理商名称" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="代理商编码">
                <a-input v-model="queryParam.code" allow-clear placeholder="请输入代理商编码" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="findByKeyword">查询</a-button>
                <a-button style="margin-left: 8px" @click="tableReset">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="addRow">新建</a-button>
        <a-button type="danger" icon="delete" @click="deleteRow">删除</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="columns"
        :data="loadData"
        :scroll="defaultScroll"
        showPagination="auto"
        :rowSelection="rowSelection"
        :totalCount="totalCount"
        :alert="true"
      >
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="editInfo(record)">编辑</a>
            <a-divider type="vertical"></a-divider>
            <a @click="lookInfo(record)">查看</a>
          </template>
        </span>
        <span slot="person" slot-scope="text, record">
          <a-tag color="blue" class="myPointer" @click="lookPerson(record)">查看</a-tag>
        </span>
      </s-table>

      <agent-create ref="agentCreate" @reload="reload" />
      <agent-drawer ref="agentDrawer"></agent-drawer>
      <person-drawer ref="personDrawer"></person-drawer>
      <agent-delete ref="agentDelete" :selectedRows="selectedRows" @reload="delReload"></agent-delete>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { agentQuery } from '@/api/agent'
import AgentCreate from './components/AgentCreate'
import AgentDrawer from './components/AgentDrawer'
import PersonDrawer from './components/PersonDrawer'
import AgentDelete from './components/AgentDelete'
import { columns } from './js/index'

export default {
  name: 'Agent',
  components: {
    STable,
    Ellipsis,
    AgentCreate,
    PersonDrawer,
    AgentDrawer,
    AgentDelete
  },
  data() {
    return {
      columns,
      totalCount: 0,
      noticeTitle: '代理商',
      queryParam: {},
      selectedRowKeys: [],
      selectedRows: [],
      loadData: parameter => {
        const params = this.paramFormat(parameter)
        Object.assign(params, this.queryParam)
        return agentQuery(params).then(res => {
          const result = this.resFormat(res, 'area')
          this.totalCount = result.totalCount
          return result
        })
      }
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    serial() {
      return this.$store.state.env.serial
    },
    host() {
      return this.$store.state.oss.host
    }
  },
  methods: {
    reload() {
      this.tableHome()
      this.$refs.table.refresh()
    },
    delReload() {
      this.selectedRows = []
      this.reload()
    },
    addRow() {
      this.$refs.agentCreate.showModal()
    },
    editInfo(record) {
      this.$refs.agentCreate.showModal(record)
    },
    lookInfo(record) {
      this.$refs.agentDrawer.showModal(record)
    },
    lookPerson(record) {
      this.$refs.personDrawer.showModal(record)
    },
    deleteRow() {
      const len = this.selectedRows.length
      console.log(this.selectedRows, 'roiw')
      if (len <= 0) {
        this.$message.warning('请选择要删除的项', 2)
      } else {
        this.$refs.agentDelete.showModal()
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    findByKeyword() {
      this.$refs.table.refresh()
    },
    tableReset() {
      this.queryParam = {}
      this.$refs.table.refresh()
    }
  }
}
</script>
